@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  height: 100%;
  display: flex;
  position: relative;
  min-height: 25px;
  .duration {
    position: absolute;
    right: 0px;
    top: -2px;
    margin: 0px;
    font-size: 11px;
    @include semiBold;
    color: $black;
  }
}

.step {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transition;
  &.isWeek {
    .content {
      margin-top: 26px;
      @media only screen and (min-width: $xl-screen) {
        width: calc(100% - 1px);
        height: calc(100% - 1.5px);
        border-radius: 2px;
      }
    }
  }
  .content {
    border: none;
    position: relative;
    width: calc(100% - 2px);
    height: calc(100% - 3px);
    border-radius: 3px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 0px;
    @media only screen and (min-width: $xl-screen) {
      width: calc(100% - 4px);
      height: calc(100% - 6px);
      border-radius: 5px;
    }
    &:hover {
      overflow: visible;
      .label {
        color: rgba($black, 0.8);
        &.hide {
          color: rgba($black, 0.8);
        }
      }
    }
    .label {
      margin-left: 6px;
      z-index: 10;
      color: rgba($black, 0.4);
      &.hide {
        color: rgba($black, 0);
      }
      p {
        text-align: left;
        color: inherit;
        margin: 0px;
        min-width: 180px;
        color: rgba($black, 0);
        @media only screen and (min-width: $xl-screen) {
          color: rgba($black, 0.4);
        }
        &:last-child {
          line-height: 11px;
          font-size: 11px;
        }
      }
    }
  }
  .tooltip {
    @include transition;
    pointer-events: none;
    opacity: 0;
    z-index: 20;
    bottom: -60px;
    left: 0px;
    position: absolute;
    background-color: $white;
    border-radius: 8px;
    padding: 6px 10px;
    width: 170px;
    box-shadow: 0px 0px 9px 8px rgba(211, 218, 226, 0.35);
    p {
      margin: 0px;
      color: $black;
      text-align: left;
    }
    .infos {
      display: flex;
      align-items: center;
      width: 100%;
      .puce {
        width: 6px;
        height: 13px;
        border-radius: 5px;
        margin-right: 5px;
      }
      &:last-child {
        justify-content: space-between;
        p {
          &:last-child {
            @include semiBold;
          }
        }
      }
    }
  }
  &:hover {
    .tooltip {
      opacity: 1;
    }
  }
}