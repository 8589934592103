@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.tooltip {
  position: relative;
  display: inline-flex; /* Ensures the container grows/shrinks with the child size */
  justify-content: center; /* Center child horizontally */
  align-items: center; /* Center child vertically */
  .message {
    @include transition;
    position: absolute;
    top: calc(100% + 5px); /* Position the message below the children */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust to exact horizontal center */
    pointer-events: none;
    opacity: 0;
    z-index: 1000;         /* Horizontally center */
    background-color: $white;
    border-radius: 8px;
    padding: 6px 10px;
    box-shadow: 0px 0px 9px 8px rgba(211, 218, 226, 0.35);
    white-space: nowrap;
    @include semiBold;
    font-size: 12px;
  }
  &:hover {
    .message {
      opacity: 1;
    }
  }
}