@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.export {
  border-radius: 5px;
  border: 1px solid #F1F0F3;
  background: #FFF;
  width: 50px;
  height: 50px;
  min-width: 50px;
  cursor: pointer;
  @include flex-center;
  @include transition;
  &:hover {
    background-color: #dfe4ea;
  }
  &.preview {
    width: auto;
    align-items: center;
    border: 1px solid $primary-color;
    background: $primary-color;
    color:  $white;
    p {
      margin: 0px 10px;
      color:  $white;
      @include semiBold;
    }
    .icon {
      color:  $white;
      margin-left: 5px;
    }
  }
  .icon {
    @include flex-center;
    font-size: 28px;
    color: $primary-color;
    &.rotate {
      animation: rotating 2s linear infinite;
    }
  }
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}