@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .day {
    background-color: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0px;
    &:hover {
      p {
        &:last-child {
          background-color: rgba($primary-color, 0.2);
        }
      }
    }
    p {
      margin: 0px;
      font-size: 14px;
      @media only screen and (min-width: $xs-screen) {
        font-size: 16px;
      }
      &:last-child {
        @include flex-center;
        margin-top: 5px;
        width: 28px;
        height: 28px;
        color: $black;
        border-radius: 5px;
        font-size: 16px;
        @media only screen and (min-width: $xs-screen) {
          width: 30px;
          height: 30px;
          font-size: 16px;
        }
        &.active {
          background-color: $primary-color;
          color: $white;
        }
      }
    }
  }
  .monday {
    width: 24px;
    height: 24px;
    border-radius: 15px;
    @include flex-center;
    border: none;
    cursor: pointer;
    background-color: rgba($primary-color, 0.2);
    @media only screen and (min-width: $xs-screen) {
      width: 28px;
      height: 28px;
      border-radius: 15px;
    }
    &.hidden {
      pointer-events: none;
      opacity: 0;
    } 
    svg {
      font-size: 22px;
      transform: translateX(4px) rotate(90deg);
    }
    &:last-child {
      transform: translateX(4px) rotate(180deg);
    }
    &:hover {
      background-color: rgba($primary-color, 0.4); 
    }
  }
}