@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .edit {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
    @include btn-edit;
    @media only screen and (min-width: $xl-screen) {
      top: 160px;
      right: 10%;
    }
  }
  .content {
    @include content;
    overflow: hidden;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      .members {
        display: flex;
        align-items: flex-end;
        h1 {
          font-size: 26px;
          margin: 0px;
          line-height: 26px;
          color: $primary-color;
        }
        p {
          margin: 0px 10px;
          line-height: 16px;
          @include semiBold;
          color: $primary-color;
        }
      }
      .new {
        margin-top: 5px;
        text-decoration: none;
        @include btn;
        display: flex;
        align-items: center;
        .icon {
          background-color: rgba($white, 0.3);
          width: 26px;
          height: 26px;
          margin-right: 10px;
          border-radius: 20px;
        }
      }
      @media only screen and (min-width: $xl-screen) {
        padding: 10px 10% 0px 10%;
        display: flex;
        .members {
          display: flex;
        }
      }
    }
    .filters {
      display: grid;
      grid-gap: 20px;
      padding: 0px 20px;
      grid-template-columns: repeat(1, 1fr);
      @media only screen and (min-width: $xl-screen) {
        padding: 0px 10% 0px 10%;
        grid-template-columns: repeat(4, 1fr);
      }
      .col {
        width: 100%;
        margin-top: 20px;
        &:not(:first-child) {
          display: none;
          @media only screen and (min-width: $xl-screen) {
            display: block;
          }
        }
      }
    }
    .list {
      max-height: calc(100vh - 255px);
      overflow: auto;
      padding: 20px;
      @include scrollbar;
      .grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
      }
      @media only screen and (min-width: $xl-screen) {
        max-height: calc(100vh - 205px);
        padding: 20px 10%;
        .grid {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

.card {
  display: flex;
  align-items: center;
  position: relative;
  background: $white;
  box-shadow: 0px 0px 9px 8px rgba(211, 218, 226, 0.35);
  border-radius: 11px;
  padding: 12px;
  text-decoration: none;
  &.structure {
    box-shadow: none;
    border: 1px solid #e6e6e8;
  }
  &.archived {
    opacity: 0.4;
  }
  .role {
    position: absolute;
    top: 6px;
    right: 10px;
    font-size: 18px;
    color: $black;
  }
  .picture {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    background-color: $primary-color;
    @include flex-center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 30px;
    }
    p {
      @include semiBold;
      color: $white;
      text-transform: uppercase;
      font-size: 18px;
      margin: 0px;
    }
  }
  .infos {
    margin-left: 10px;
    h3, h4 {
      margin: 0px;
      text-decoration: none;
    }
    h3 {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 5px;
      color: $black;
    }
    h4 {
      @include regular;
      line-height: 14px;
    }
  }
}