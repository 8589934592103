@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.modale {
  padding: 20px;
  min-height: 385px;
  h2 {
    color: $primary-color;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .tabs {
    display: flex;
    margin-bottom: 20px;
    border-bottom: 1px solid #E9E9E9;
    button {
      display: block;
      width: 100%;
      @include semiBold;
      border: none;
      background-color: transparent;
      padding: 0px;
      cursor: pointer;
      .bar {
        width: 100%;
        height: 7px;
        margin-top: 6px;
        background-color: $primary-color;
        transform: translateY(4px);
        border-radius: 5px;
        opacity: 0;
      }
      &.active {
        .bar {
          opacity: 1;
          height: 7px;
        }
      }
    }
  }
}