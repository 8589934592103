@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .back {
    position: absolute;
    top: 20px;
    left: 20px;
    text-decoration: none;
    text-transform: uppercase;
    color: $primary-color;
    display: flex;
    align-items: center;
    p {
      @include semiBold;
      font-size: 14px;
      margin-left: 8px;
      color: $primary-color;
      margin-bottom: 0px;
    }
  }
  .user {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    .name {
      @include semiBold;
      color: $black;
      font-size: 16px;
      margin: 0px 12px;
    }
    .picture {
      height: 40px;
      width: 40px;
      border-radius: 30px;
      background-color: $primary-color;
      @include flex-center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
      }
      p {
        @include semiBold;
        position: relative;
        text-align: center;
        width: 35px;
        color: $white;
        font-size: 13px;
        margin: 0px;
      }
    }
  }
  .content {
    @include content;
    overflow: hidden;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      h1 {
        font-size: 26px;
        margin: 0px;
        line-height: 26px;
        color: $black;
        display: none;
      }
      @media only screen and (min-width: $xl-screen) {
        padding: 10px 5% 0px 5%;
        display: flex;
        h1 {
          display: block;
        }
      }
    }
    .filters {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0px 22px;
      justify-content: space-between;
    }
    .mobile-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px 0px 20px;
      h4 {
        margin: 0px 4px;
        text-align: center;
        font-size: 18px;
        color: $black;
      }
      @media only screen and (min-width: $xl-screen) {
        display: none;
      }
    }
    .list {
      max-height: calc(100vh - 270px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px 20px;
      @include scrollbar;
    }
  }
  .new {
    position: absolute;
    bottom: 80px;
    right: 20px;
    text-decoration: none;
    @include btn;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 55px;
    height: 55px;
    .icon {
      background-color: rgba($white, 0.3);
      width: 26px;
      height: 26px;
      border-radius: 20px;
    }
    .icon:not(.loader):first-child {
      margin-left: 0px;
    }
  
  }
  &.member {
    .content {
      .list {
        max-height: calc(100vh - 218px);
      }
      .week {
        margin-top: 20px;
        .title {
          padding: 0px 5% 20px 5%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .select-date {
            display: flex;
            align-items: center;
            margin-top: 20px;
            margin-right: 20px;
            button {
              width: 28px;
              height: 28px;
              border-radius: 15px;
              @include flex-center;
              border: none;
              cursor: pointer;
              background-color: rgba($primary-color, 0.2); 
              svg {
                font-size: 22px;
                transform: translateX(4px) rotate(90deg);
              }
              &:last-child {
                transform: translateX(4px) rotate(180deg);
              }
              &:hover {
                background-color: rgba($primary-color, 0.4); 
              }
            }
            h4 {
              margin: 0px;
              min-width: 260px;
              text-align: center;
              font-size: 18px;
              color: $black;
            }
          }
        }
        .labels {
          width: 100%;
          padding: 0px 20px;
          display: none;
          @media only screen and (min-width: $xl-screen) {
            padding: 12px 5% 6px 5%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
          .row {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            &:first-child {
              width: 220px;
            }
            &:last-child {
              width: calc(100% - 220px);
              .hour {
                position: relative;
                p {
                  margin: 0px;
                  line-height: 14px;
                }
              }
            }
          }
        }
        .list {
          padding: 0px 5% 0px 5%;
          min-height: calc(100vh - 145px);
          max-height: calc(100vh - 145px);
          overflow-y: auto;
          .days {
            background-color: $white;
            border-radius: 7px;
          }
        }  
      }
    }
  }
}
