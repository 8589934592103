@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.modale {
  .form {
    width: 100%;
    .row {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr;
      .container-field {
        @include containerField();
      }
    }
  }
  .error {
    margin-bottom: 20px;
  }
  .actions {
    button {
      @include btn;
    }
  }
}