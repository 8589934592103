@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  .toggle {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F1F0F3;
    button {
      @include flex-center;
      @include transition;
      width: 50%;
      background-color: transparent;
      border: none;
      padding: 10px 0px;
      border-bottom: 3px solid transparent;
      cursor: pointer;
      p {
        @include semiBold;
        @include transition;
        margin: 0px;
      }
      &.active {
        border-bottom: 3px solid $primary-color;
        p {
          color: $black;
        }
      }
      .count {
        @include flex-center;
        @include transition;
        margin-left: 5px;
        width: 18px;
        height: 18px;
        font-size: 11px;
        border-radius: 18px;
        color: $white;
        background-color: $warn;
        transform: scale(0);
        &.active {
          transform: scale(1);
        }
      }
    }
  }
  .list {
    max-height: calc(100vh - 180px);
    overflow: auto;
    @include scrollbar;
  }
}

.notif {
  padding: 10px;
  display: flex;
  border-bottom: 1px solid #F1F0F3;
  .author {
    @include flex-center;
    width: 32px;
    height: 32px;
    border-radius: 25px;
    background-color: $primary-color;
    margin-right: 15px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 25px;
      object-fit: cover;
    }
    p {
      margin: 0px;
      @include semiBold;
      position: relative;
      text-align: center;
      width: 35px;
      color: $white;
      font-size: 12px;
      text-decoration: none;
    }
  }
  .message {
    margin-top: 6px;
    p {
      font-size: 14px;
      margin: 0px;
      &:first-child {
        @include semiBold;
        font-size: 14px;
        color: $primary-color;
      }
    }
    .message {
      color: $black;
    }
    .actions {
      margin-bottom: 10px;
      .valid {
        margin: 5px 0px;
        button {
          @include regular;
          cursor: pointer;
          border:  1px solid $primary-color;
          color: $primary-color;
          background-color: transparent;
          border-radius: 4px;
          margin-right: 10px;
          &:first-child {
            background-color: $primary-color;
            color: $white;
          }
        }
      }
      .redirect {
        @include regular;
        background-color: transparent;
        padding: 0px;
        border: none;
        color: $text-color;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}