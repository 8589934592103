@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  background-color: $primary-color;
  top: 0px;
  right: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 10;
  transform: translateX(100%);
  @include transition;
  &.active {
    transform: translateX(0px);
  }
  @media only screen and (min-width: $xl-screen) {
    width: 450px;
  }
  .header {
    display: flex;
    padding: 20px;
    button {
      display: flex;
      align-items: center;
      color: $white;
      background-color: transparent;
      border: none;
      cursor: pointer;
      svg {
        font-size: 16px;
        margin-right: 10px
      }
      p {
        @include semiBold;
        color: $white;
        margin: 0px;
        font-size: 26px;
        line-height: 26px;
      }
    }
  }
  .days {
    width: 100%;
    padding: 0px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .day {
      background-color: transparent;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      padding: 4px;
      p {
        margin: 0px;
        font-size: 14px;
        @media only screen and (min-width: $xs-screen) {
          font-size: 16px;
        }
        &:last-child {
          @include flex-center;
          margin-top: 5px;
          width: 28px;
          height: 28px;
          border-radius: 5px;
          font-size: 16px;
          @media only screen and (min-width: $xs-screen) {
            width: 30px;
            height: 30px;
            font-size: 16px;
          }
          &.active {
            background-color: $white;
            color: $primary-color;
          }
        }
      }
    }
  }
  .week {
    margin-top: 10px;
    h4 {
      color: $white;
      font-size: 18px;
      padding: 0px 20px;
      margin-bottom: 10px;
    }
    .timeline {
      max-height: 63vh;
      padding: 0px 20px 10px 20px;
      overflow-y: auto;
    }
  }
  .actions {
    position: absolute;
    width: 100%;
    left: 0px;
    display: flex;
    justify-content: space-between;
    padding: 20px;
    bottom: 0px;
    .btn {
      display: flex;
      align-items: center;
      @include flex-center;
      border: none;
      background-color: $white;
      padding: 10px 10px;
      border-radius: $radius;
      cursor: pointer;
      p {
        @include semiBold;
        margin: 0px;
        color: $black;
      }
      &:first-child {
        width: calc(100% - 72px);
        position: relative;
        .icon {
          position: absolute;
          left: 10px;
          margin-left: 0px;
          font-size: 24px;
        }
      }
      .icon {
        @include flex-center;
        margin-left: 0px;
        width: 32px;
        height: 32px;
        border-radius: 16px;
        background-color: rgba($black, 0.1);
        color: rgba($black, 0.6);
        font-size: 20px;
        &.danger {
          background-color: rgba($warn, 0.1);
          color: $warn;
        }
      }
    }
  }
  .form {
    .field {
      margin-top: 8px;
      margin-bottom: 30px;
      padding: 0px 25px;
      label {
        color: $white;
        display: block;
        margin-bottom: 5px;
      }
      .days {
        padding: 0px;
        margin-bottom: 0px;
      }
      textarea {
        width: 100%;
        max-width: 100%;
        min-height: 200px;
        border-radius: $radius;
        border: 1px solid $white;
        background-color: transparent;
        padding: 5px 10px;
        color: $white;
      }
    }
    .actions {
      display: flex;
      justify-content: center;
    }
  }
}