@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.modale {
  padding: 20px;
  h2 {
    color: $primary-color;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .field {
    margin-bottom: 10px;
    label {
      @include semiBold;
      color: rgba($black, 0.5);
      display: block;
      margin-bottom: 5px;
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    button {
      display: flex;
      @include transition;
      @include btn;
      cursor: pointer;
    }
  }
  .errors-container {
    margin-top: 20px;
   
    .users {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding-bottom: 10px;
      &:not(:first-child) {
        border-top: 1px solid $medium-warn;
        padding-top: 10px;
      }
      .user {
        p {
          @include semiBold;
          color: $medium-warn;
          margin: 0px;
        }
        .errors {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          margin-top: 10px;
          .error {
            background-color: rgba($warn, 0.2);
            @include semiBold;
            padding: 8px 12px;
            border-radius: 6px;
            margin-bottom: 5px;
            p {
              @include semiBold;
              color: $warn;
              margin: 0px;
            }
          }
        }
        .alerts {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .alert {
            background-color: rgba($medium-warn, 0.2);
            @include semiBold;
            padding: 8px 12px;
            border-radius: 6px;
            margin-bottom: 5px;
            p {
              @include semiBold;
              color: $medium-warn;
              margin: 0px;
            }
          }
        }
      }
    }
  }
  .selects {
    margin-top: 20px;
    overflow-y: auto;
    max-height: 400px;
    @include scrollbar;
    .select {
      .title {
        height: 38px;
        border-bottom: 1px solid #E6E6E6;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .toggle {
          @include flex-center;
          width: 25px;
          height: 25px;
          font-size: 20px;
          padding: 0px;
          background-color: transparent;
          border: none;
          cursor: pointer;
          color: #8D97A0;
          &.open {
            transform: rotate(180deg);
          }
        }
        .label {
          display: flex;
          label {
            cursor: pointer;
            color: $primary-color;
            margin: 0px;
            @include semiBold;
            span {
              color: #5E8DCF;
            }
          }
          .check {
            @include flex-center;
            border: 1px solid #F1F0F3;
            width: 20px;
            height: 20px;
            border-radius: 5px;
            color: $primary-color;
            margin-right: 5px;
          }
        }
      }
      .list {
        overflow: hidden;
        @include transition;
        .options {
          padding: 15px 0px;
          .option {
            display: flex;
            cursor: pointer;
            font-size: 12px;
            .check {
              @include flex-center;
              border: 1px solid #F1F0F3;
              width: 20px;
              height: 20px;
              border-radius: 5px;
              color: $primary-color;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
}