@mixin regular {
  font-family: "Poppins-Regular", sans-serif;
}

@mixin semiBold {
  font-family: "Poppins-SemiBold", sans-serif;
}

@mixin transition {
  transition: all 0.2s ease-in-out;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba($black, 0.5);
    border-radius: 5px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: $black;
  }
}

@mixin label {
  @include regular;
  font-size: 14px;
  line-height: 14px; 
  color: $text-color;
  white-space: nowrap;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center
}


@mixin btn {
  @include semiBold;
  @include transition;
  align-items: center;
  justify-content: center;
  font-size: $button;
  background-color: $primary-color;
  padding: 11px 20px;
  color: $white;
  border: solid 1px $primary-color;
  border-radius: $radius;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  @include remove-tap-highlight();
  span {
    vertical-align: middle;
  }

  .icon {
    position: relative;
    top: -1px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 0px;

    &.loader > div {
      position: initial;
    }
  }

  .icon:not(.loader):first-child {
    margin-left: -5px;
  }

  span:first-child:not(.icon),
  .icon + span {
    position: relative;
    top: -1px;
  }

  span + .icon,
  .icon + span {
    margin-left: 10px;
  }

  &.secondary {
    background-color: $secondary-color;
    border: solid 1px $secondary-color;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.warn {
    background-color: $warn;
    border: solid 1px $warn;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.grey {
    background-color: #BCC2C5;
    border: solid 1px #BCC2C5;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.black {
    background-color: $black;
    border: solid 1px $black;
    color: $white;
    svg {
      color: $white;
    }
  }

  &.light {
    background-color: rgba(113, 122, 151, 0.04);
    border: solid 1px rgba(113, 122, 151, 0.04);
    color: $text-color;
  }

  &.third {
    background-color: $white;
    border: solid 1px $text-color;
    color: $text-color;
  }

  &.dashed {
    background-color: rgba(#96A5B8, 0.2);
    border: 1px dashed rgba($text-color, 0.2);
    color: $text-color;
    svg {
      color: $text-color;
    }
    >span {
      text-decoration: underline;
    }
  }


  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

@mixin container {
  margin: auto;
  width: 100%;
  max-width: 1024px;
  padding: $margin-md;
  margin: 0 auto;
}

@mixin page {
  position: relative;
  margin: auto;
  flex: 1;
  padding: $margin-md;
  margin: 0 auto;
  overflow: auto;
  max-height: 100%;
}

@mixin initials {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-right: $margin-xs;
  border-radius: 50%;
  background-color: $primary-color;
  border: 2px solid #f0f0f0;
  background-position: center;
  background-size: cover;

  > span {
    position: relative;
    font-family: "DMSans";
    font-size: $medium;
    color: $white;
  }
}

@mixin containerField {
  margin-bottom: 20px;
}

@mixin remove-highlight() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin remove-tap-highlight() {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
}

@mixin content {
  height: calc(100vh - 125px);
  width: 100%;
  overflow-y: auto;
  @include scrollbar;
  @media only screen and (min-width: $xl-screen) {
    background-color: #F6F7F8;
    height: calc(100vh - 70px);
    margin-top: 70px;
  }
}

@mixin authTemplate {
  width: 100%;
  height: 100vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .picture {
    background-color: $primary-color;
    background-image: url("../assets/images/bg-login.png");
    background-repeat: no-repeat;
    height: 36vh;
    width: calc(100% - 40px);
    margin-top: 20px;
    border-radius: 7px;
    @include flex-center();
    align-items: flex-end;
    display: none;
    img {
      width: 80%;
      max-width: 500px;
      height: 100%;
    } 
  }

  .content {
    width: 100%;
    height: 100%;
    @include flex-center();
    .form {
      width: 100%;
      max-width: 350px;
      padding: $margin-lg;
      display: flex;
      flex-direction: column;
      align-items: center;

      .logo {
        max-width: calc(100% - 60px);
        width: 200px;
        margin-bottom: 30px;
        @media only screen and (min-width: $xl-screen) {
          width: 200px;
          margin-bottom: 20px;
        }
        img {
          width: 100%;
        }
      }
      h3 {
        color: $primary-color;
        font-size: 26px;
        text-align: center;
        width: 100%;
      }
      button {
        @include btn;
        margin-bottom: 20px;
      }
    }
}
  @media only screen and (min-width: $xl-screen) {
    flex-direction: row;
    .picture {
      display: flex;
      width: calc(50% - 40px);
      height: calc(100% - 40px);
      margin-left: 20px;
      margin-top: 0px;
    }
    .content {
      width: 50%;
    }
  }
}

@mixin btn-edit {
  border: none;
  background-color: transparent;
  @include flex-center();
  cursor: pointer;
  span {
    display: none;
    @include semiBold();
    margin-left: 10px;
    color: $primary-color;
    text-transform: uppercase;
  }
  .icon {
    background-color: #A2B1CE;
    width: 41px;
    height: 41px;
    border-radius: 22px;
    border: none;
    @include flex-center();
  }
  @media only screen and (min-width: $xl-screen) {
    .icon {
      background-color: #A2B1CE;
      width: 32px;
      height: 32px;
      border-radius: 22px;
      border: none;
      @include flex-center();
    }
    span {
      display: block;
    }
  }
}
@mixin dotLoader() {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: $primary-color;
  border-radius: 7px;
  animation-iteration-count: infinite;
  animation-duration: 1s;
  animation-name: rotate;
  margin-right: $margin-sm;
}
@keyframes rotate {
  0% {
    transform: rotateX(0) rotateY(0);
  }
  50% {
    transform: rotateX(-180deg) rotateY(0);
  }
  100% {
    transform: rotateX(-180deg) rotateY(-180deg);
  }
}
