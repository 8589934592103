@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.search {
  width: 100%;
  background-color: $white;
  border: 1px solid #F1F0F3;
  border-radius: 5px;
  display: flex;
  height: 50px;
  position: relative;
  input {
    background-color: transparent;
    border: none;
    width: 100%;
    padding: 10px 15px;
    @include semiBold;
  }
  .actions {
    position: absolute;
    right: 0px;
    height: 50px;
    display: flex;
    align-items: center;
    button {
      margin-right: 5px;
      background-color: transparent;
      border: none;
      font-size: 18px;
      padding: 0px;
      @include flex-center;
      cursor: pointer;
      color: rgba($black, 0.5);
      &:last-child {
        background-color: rgba($success, 0.8);
        margin-right: 10px;
        height: 30px;
        color: $white;
        width: 30px;
        border-radius: 15px;
      }
    }
  }
}