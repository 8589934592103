@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  position: relative;
  .toggle {
    position: relative;
    cursor: pointer;
    margin-top: 0px;
    background-color: $white;
    border: 1px solid #F1F0F3;
    border-radius: 5px;
    height: 50px;
    min-width: 50px;
    &:hover {
      border: 1px solid $black;
    }
    p {
      @include semiBold;
      position: absolute;
      top: 15px;
      left: 0px;
      width: 48px;
      text-align: center;
      color: $black;
    }
  }
  .picker {
    border-radius: 5px;
    top: 54px;
    left: 0px;
    position: absolute;
    z-index: 300;
    background-color: $white;
    box-shadow: 0px 0px 9px 8px rgba(211, 218, 226, 0.35);
  }
}