@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .content {
    border-top: 1px solid #F1F0F3;
    @include content;
    @media only screen and (min-width: $xl-screen) {
      background-color: $white;
    }
  }
}
