@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  height: 80px;
  p {
    margin: 0px;
  }
  .deg {
    line-height: 31px;
    font-size: 39px;
    margin-left: 20px;
    p {
      color: $primary-color;
      margin: 0px;
      font-size: 20px;
      line-height: 24px;
      margin-top: 4px;
      span {
        @include semiBold;
      }
      &:first-child {
        @include semiBold;
        font-size: 24px;
        line-height: 24px;
        margin-top: 8px;
      }
    }
  }
  .icon {
    color: $primary-color;
    font-size: 78px;
    height: 80px;
  }
}