@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .edit {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
    @include btn-edit;
    @media only screen and (min-width: $xl-screen) {
      top: 160px;
      right: 10%;
    }
  }
  .content {
    @include content;
    overflow: hidden;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;
      min-height: 54px;
      h1 {
        font-size: 26px;
        margin: 0px;
        line-height: 26px;
        color: $primary-color;
        display: none;
      }
      .new {
        margin-top: 5px;
        text-decoration: none;
        @include btn;
        display: flex;
        align-items: center;
        .icon {
          background-color: rgba($white, 0.3);
          width: 26px;
          height: 26px;
          margin-right: 10px;
          border-radius: 20px;
        }
      }
      @media only screen and (min-width: $xl-screen) {
        padding: 10px 10% 0px 10%;
        display: flex;
        h1 {
          display: block;
        }
      }
    }
    .list {
      max-height: calc(100vh - 185px);
      overflow: auto;
      padding: 20px;
      @include scrollbar;
      @media only screen and (min-width: $xl-screen) {
        max-height: calc(100vh - 136px);
        padding: 20px 10%;
      }
      .grid {
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
        @media only screen and (min-width: $sm-screen) {
          grid-template-columns: repeat(2, 1fr);
        }
        @media only screen and (min-width: $xl-screen) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }
  }
}

.card {
  display: block;
  background: $white;
  box-shadow: 0px 0px 9px 8px rgba(211, 218, 226, 0.35);
  border-radius: 11px;
  padding: 8px;
  text-decoration: none;
  .picture {
    height: 144px;
    width: 100%;
    border-radius: 8px;
    background-color: rgba($black, 0.1);
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
  }
  h3, h4 {
    margin: 0px;
    text-decoration: none;
  }
  h3 {
    color: $primary-color;
    margin: 8px 0px 0px 10px;
  }
  h4 {
    @include regular;
    margin: 0px 0px 0px 10px;
  }

}