@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .edit {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
    @include btn-edit;
    @media only screen and (min-width: $xl-screen) {
      top: 85px;
      right: 10%;
    }
  }
  .content {
    @include content;
    @media only screen and (min-width: $xl-screen) {
      background-color: $white;
      position: relative;
    }
  }
}
