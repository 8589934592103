@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  label {
    @include label;
  }
  
  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
    }
  }
  
  input {
    appearance: none;
    padding: 0px;
    margin: 0px 0px 0px 20px;
    border-radius: 4px;
    border: none;
    width: 52px;
    border-radius: $radius;
  }
  .block {
    position: absolute;
    pointer-events: none;
    width: 63px;
    left: 90px;
    height: 40px;
    border: solid 1px #d8d7da;
    border-radius: $radius;
  }
  &.small {
    margin: 0px;
    .block {
      display: none;
    }
    input {
      margin: 0px;
      border-radius: $radius;
      overflow: hidden;
    }
  }

  input[type="color"]::-moz-color-swatch {
    border: none;
  }
  
  input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 0;
  }
  
  input[type="color"]::-webkit-color-swatch {
    border: none;
  }
  
}
