@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  .delete {
    @include btn;
    background-color: transparent;
    border: 1px solid $warn;
    color: $warn;
    margin: 20px;
    &:hover {
      background-color: rgba($warn, 0.3);
    }
    @media only screen and (min-width: $xl-screen) {
      margin: 0px;
    } 
  }
  .end {
    @include btn;
    background-color: transparent;
    border: 1px solid #d8d7da;
    color: #c5c5c5;
 margin: 0px 0px 20px 0px;
    width: 100%;
    &:hover {
      background-color: rgba(#d8d7da, 0.3);
    }
    @media only screen and (min-width: $xl-screen) {
      margin: 0px 0px 20px 0px;
    } 
  }
  .content {
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    .col {
      .container-field {
        @include containerField();
      }
      .error {
        margin:  0px 0px 0px 20px;
      }
      .periods {
        display: flex;
        flex-direction: column;
        width: calc(100%);
        margin: 0px;
        @media only screen and (min-width: $xl-screen) {
          margin:  28px 0px 0px 20px;
          width: calc(100% - 20px);
        }
        .period {
          width: 100%;
          display: flex;
          flex-direction: column;
          border: 1px solid #CFCFCF;
          border-radius: 5px;
          margin-bottom: 10px;
          @media only screen and (min-width: $md-screen) {
            flex-direction: row;
            align-items: center;
            height: 46px;
          }
          .item {
            width: 100%;
            height: 38px;
            position: relative;
            display: flex;
            align-items: center;
            @media only screen and (min-width: $md-screen) {
              width: 33%;
            }
            &:not(:first-child) {
              p {
                width: 100%;
                padding-left: 15px;
                span {
                  color: $black;
                }
              }
              @media only screen and (min-width: $md-screen) {
                border-left: 1px solid #CFCFCF;
                text-align: center;
                padding-left: 0px;
              }
            }
            input {
              max-width: 80px;
              text-align: center;
              background-color: rgba(#CFCFCF, 0.2);
              border-radius: 5px;
            }
            p {
              margin: 0px;
            }
          }
          .delete-item {
            width: 35px;
            @include flex-center;
            button {
              background-color: transparent;
              cursor: pointer;
              border: none;
              @include flex-center;
              font-size: 20px;
              color: $warn;
              display: none;
            }
          }
          &:hover {
            .delete-item {
              button {
                display: flex;
              }
            }
          }
        }
      }
      .add {
        float: right;
        margin-top: 10px;
        @include btn-edit;
        .icon {
          width: 26px;
          height: 26px;
        }
      }
    }
  }
  @media only screen and (min-width: $xl-screen) {
    .content {
      padding: 0px;
      flex-direction: row;
      .col {
        width: 70%;
        &:first-child {
          width: 30%;
        }
      }
    } 
  }
}

.modal {
  @include flex-center;
  flex-direction: column;
  padding: 20px;
  .container-field {
    width: 80%;
    @include containerField();
  }
  .submit {
    @include btn;
    background-color: transparent;
    border: 1px solid $warn;
    color: $warn;
    margin: 20px;
    &:hover {
      background-color: rgba($warn, 0.3);
    }
    @media only screen and (min-width: $xl-screen) {
      margin: 0px;
    } 
  }
}