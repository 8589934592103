@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  position: relative;

  @media only screen and (min-width: $xl-screen) {
    border-bottom: 1px solid #F1F0F3;
    margin-top: 0px;

    &:hover {
      .actions {
        display: flex;
      }

      &:not(.isNote) {
        .user {
          .note {
            display: block;
          }
        }
      }
    }
  }

  &:not(.isNote) {
    .user {
      .note {
        display: none;
      }
    }
  }

  .valid {
    position: absolute;
    width: 40px;
    height: 50px;
    left: -40px;
    @include flex-center;
    font-size: 26px;
    color: $success;
  }

  .line {
    position: relative;
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;

    @media only screen and (min-width: $xl-screen) {
      width: 220px;
      border-right: 1px solid #F1F0F3;
      padding: 8px 12px;
    }

    p {
      margin: auto;
      @include semiBold;
      color: $black;
      font-size: 15px;

      @media only screen and (min-width: $xl-screen) {
        margin: 0px 5px;
      }
    }

    .name {
      position: absolute;
      width: 60vw;
      top: -26px;
      left: 44px;
      @include semiBold;
      line-height: 15px;
      color: $black;
      font-size: 15px;
      margin: 0px 0px 0px 10px;

      @media only screen and (min-width: $xl-screen) {
        position: relative;
        width: auto;
        top: 0px;
        left: 0px;
      }
    }
  }

  .user {
    position: relative;
    width: 50px;
    display: flex;
    align-items: center;
    padding: 5px;

    @media only screen and (min-width: $xl-screen) {
      width: 220px;
      border-right: 1px solid #F1F0F3;
      padding: 8px 12px;
    }

    .name {
      position: absolute;
      width: 60vw;
      top: -26px;
      left: 44px;
      @include semiBold;
      text-transform: capitalize;
      line-height: 15px;
      color: $black;
      font-size: 15px;
      margin: 0px 0px 0px 10px;

      @media only screen and (min-width: $xl-screen) {
        position: relative;
        width: auto;
        top: 0px;
        left: 0px;
      }
    }

    .picture {
      height: 35px;
      width: 35px;
      border-radius: 30px;
      background-color: $primary-color;
      @include flex-center;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
      }

      p {
        @include semiBold;
        position: relative;
        text-align: center;
        text-transform: uppercase;
        width: 35px;
        color: $white;
        font-size: 13px;
        margin: 0px;
      }
    }

    .note {
      display: none;
      margin: 0px;
      background-color: transparent;
      padding: 0px;
      border: none;

      @media only screen and (min-width: $xl-screen) {
        display: block;
        position: absolute;
        @include semiBold;
        right: 3px;
        top: 0px;
        font-size: 18px;
        color: $text-color;
        cursor: pointer;
      }
    }

    .hours {
      display: none;
      margin: 0px;

      @media only screen and (min-width: $xl-screen) {
        display: block;
        position: absolute;
        @include semiBold;
        right: 10px;
        bottom: 6px;
        font-size: 12px;
      }
    }
  }

  .content {
    width: calc(100% - 50px);

    @media only screen and (min-width: $xl-screen) {
      width: calc(100% - 220px);
      padding: 0px;
    }

    .timeline {
      width: 100%;
      padding: 4px;
      height: 50px;
      background-color: transparent;
      border: none;
      cursor: pointer;

      @media only screen and (min-width: $xl-screen) {
        padding: 0px;
      }

      &.forcast {
        cursor: initial;
      }

      .week {
        width: 100%;
        display: flex;

        .day {
          padding: 4px;
          width: calc(100% / 7);
        }
      }
    }
  }

  .alert {
    @include flex-center;
    position: absolute;
    top: 8px;
    right: 2px;
    background-color: transparent;
    border: none;
    cursor: pointer;

    @media only screen and (min-width: $xl-screen) {
      position: absolute;
      top: 8px;
      right: -50px;
    }

    .icon {
      @include flex-center;
      margin-left: 0px;
      width: 32px;
      height: 32px;
      border-radius: 16px;
      background-color: rgba($black, 0.1);
      color: rgba($black, 0.6);
      font-size: 20px;

      &.danger {
        background-color: rgba($warn, 0.1);
        color: $warn;

        svg {
          transform: translateY(-2px);
        }
      }
    }

    p {
      @include transition;
      position: absolute;
      width: 200px;
      right: 45px;
      top: 0px;
      z-index: 300;
      border-radius: 11px;
      border: 1px solid #EFEFEF;
      background: #FFF;
      opacity: 0;
      box-shadow: 0px 0px 9px 8px rgba(211, 218, 226, 0.35);
      text-align: left;
      padding: 10px;
    }

    &:hover {
      p {
        opacity: 1;
      }
    }
  }

  .actions {
    display: none;

    @media only screen and (min-width: $xl-screen) {
      position: absolute;
      bottom: 0px;
      right: 0px;
      border-top-left-radius: 5px;
      color: rgba($black, 0.6);
      background-color: $white;
    }

    button {
      @include flex-center;
      background-color: transparent;
      border: none;
      cursor: pointer;

      .icon {
        @include flex-center;
        font-size: 18px;
        width: 22px;
        height: 26px;
        color: rgba($black, 0.4);

        &:hover {
          color: $success;
        }

        &.danger {
          font-size: 16px;

          &:hover {
            color: $warn;
          }
        }
      }

      p {
        @include transition;
        position: absolute;
        width: 200px;
        right: 45px;
        top: 0px;
        border-radius: 11px;
        border: 1px solid #EFEFEF;
        background: #FFF;
        opacity: 0;
        box-shadow: 0px 0px 9px 8px rgba(211, 218, 226, 0.35);
        text-align: left;
        padding: 10px;
      }

      &:hover {
        p {
          opacity: 1;
        }
      }
    }
  }
}