@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  width: 100%;
  background-color: $white;
  border: 1px solid #F1F0F3;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  position: relative;
  padding: 0px 12px;
  .value {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    p {
      margin: 0px;
      @include semiBold;
      color: $black;
      svg {
        background-color: rgba($black, 0.1);
      }
    }
    button {
      font-size: 18px;
      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  button {
    background-color: transparent;
    border: none;
  }
  .options {
    z-index: 200;
    width: 100%;
    overflow: hidden;
    top: 46px;
    left: 0px;
    background-color: $white;
    position: absolute;
    display: flex;
    flex-direction: column;
    button {
      display: flex;
      align-items: center;
      padding: 8px 12px;
      cursor: pointer;
      &:hover {
        background-color: rgba($black, 0.1)
      }
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
      .puce {
        width: 12px;
        height: 12px;
        border-radius: 12px;
        margin-right: 8px;
      }
      p {
        margin: 0px;
        @include semiBold;
        color: $black;
      }
    }
  }
}