@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  display: flex;
  justify-content: space-between;
  border: 1px solid transparent;
  border-bottom: 1px solid #F1F0F3;
  margin-top: 0px;
  .date {
    width: 220px;
    min-height: 50px;
    border-right: 1px solid #F1F0F3;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
      margin: 0px;
      &.hours {
        display: none;
        margin: 0px;
        @media only screen and (min-width: $xl-screen) {
          display: block;
          @include semiBold;
          right: 10px;
          font-size: 12px;
        }
      }
    }
  }
  .timeline {
    width: calc(100% - 220px);
    padding: 0px;
  }
  &.active {
    background-color: $white;
    border-radius: 5px;
    border: 1px solid #b1b1b1;
    .date {
      p {
        @include semiBold;
      }
    }
  }
}