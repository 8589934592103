@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.modale {
  padding: 20px;
  h2 {
    color: $primary-color;
    margin-bottom: 20px;
    font-size: 18px;
  }
  .form {
    .container-field {
      @include containerField();
      .icon {
        display: flex;
        align-items: center;
        label {
          display: block;
          min-width: 70px;
          @include regular;
          font-size: 14px;
          line-height: 14px; 
          color: $text-color;
          white-space: nowrap;
        }
      }
    }
  }
  .lines {
    margin-bottom: 20px;
    h2 {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .input {
      width: 100%;
      border: solid 1px #d8d7da;
      border-radius: $radius;
      display: flex;
      justify-content: space-between;
      align-items: center;
      p {
        padding: 0px 10px; 
        @include regular;
        font-size: 16px;
        margin: 0px;
      }
      button {
        @include btn;
        cursor: pointer;
        opacity: 1;
      }
    }
    .list {
      margin-top: 20px;
      .line {
        border: solid 1px #d8d7da;
        border-radius: $radius;
        display: flex;
        justify-content: space-between;
        padding: 8px 10px;
        margin-top: 10px;
        p {
          margin: 0px;
          font-size: 16px;
          color: $black;
        }
        button {
          padding: 0px;
          background-color: transparent;
          border: none;
          font-size: 19px;
          opacity: 0;
          pointer-events: none;
        }
        &:hover {
          background-color: rgba($primary-color, 0.1);
          border: solid 1px $primary-color;
          button {
            cursor: pointer;
            pointer-events: all;
            opacity: 0.4;
          }
        }
      }
    }
  }
  .actions {
    button {
      @include btn;
    }
  }
}