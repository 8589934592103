@import "./_variables.scss";
@import "./_mixins.scss";

html,
body,
#root {
  height: 100%;
  min-height: 100%;
  background-color: $bg-color;
}

:focus-visible {
  outline: none;
}

body {
  margin: 0;
  font-family: "Poppins-Regular", sans-serif;
  font-size: $regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --bg-dropzone: transparent;
  overflow: hidden;
}

.App {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}

#modal {
  position: absolute;
}

* {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5 { 
  color: $text-color;
  margin-top: 0;
}

h1 {
  @include semiBold;
  font-weight: normal;
  color: $text-color;
}

h3,
h4 {
  @include semiBold;
  font-weight: normal;
  color: $text-color;
}

h1 {
  font-size: $h1;
}

h2 {
  @include semiBold;
  font-size: $h2;
}

h3 {
  font-size: $h3;
  margin-bottom: $margin-sm;
}

h4 {
  font-size: $regular;
}

h5 {
  @include semiBold;
  font-weight: normal;
  font-size: $regular;
  margin-bottom: $margin-sm;
  color: $text-color;
}

p,
a,
li,
input,
textarea {
  @include regular;
  color: $text-color;
  font-size: $regular;
  &.bold {
    @include semiBold;
  }
  &.small {
    font-size: $regular;
  }
}

p.error {
  color: $warn;
}

p {
  margin-top: 0;
}

a {
  text-underline-offset: 2px;
  @include remove-tap-highlight();
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px transparent inset !important;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hide-on-mobile {
  display: none;
  @media screen and (min-width: $sm-screen) {
    display: block;
  }
}

.only-on-mobile {
  display: block;
  @media screen and (min-width: $sm-screen) {
    display: none;
  }
}

.rdp {
  --rdp-cell-size: 40px; /* Size of the day cells. */
  --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
  --rdp-accent-color: #1C376B; /* Accent color for the background of selected days. */
  --rdp-background-color: #dee9f1; /* Background color for the hovered/focused elements. */
  --rdp-accent-color-dark: #1C376B; /* Accent color for the background of selected days (to use in dark-mode). */
  --rdp-background-color-dark: #1C376B; /* Background color for the hovered/focused elements (to use in dark-mode). */
  --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
  --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
  --rdp-selected-color: #fff; /* Color of selected day text */
}