@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .content {
    position: relative;
    @include content;

    @media only screen and (min-width: $xl-screen) {
      background-color: $white;
      position: relative;
    }

    .nav {
      position: absolute;
      top: 15px;
      right: 15px;
      display: none;

      button {
        background-color: $white;
        color: $primary-color;
        width: 40px;
        height: 40px;
        @include flex-center;
        border: 1px solid #F1F0F3;
        cursor: pointer;

        &:first-child {
          margin-right: 10px;
        }
      }

      @media only screen and (min-width: $xl-screen) {
        display: flex;
        right: 10%;
        top: 15px;
      }
    }
  }
  .bg {
    left: 0px;
    height: 130px;
    width: 100%;
    background-color: #F6F7F8;
    background-image: url("../../assets/images/water.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
  }

    .avatar {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 20px;
      .name {
        margin: 0px 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        h1 {
          @include semiBold;
          font-size: 26px;
          line-height: 22px;
          margin: 0px;
          color: $primary-color;
        }
        p {
          font-size: 20px;
          line-height: 18px;
          margin: 5px 0px;
        }
        .poles {
          display: flex;
          p {
            background-color: transparent;
            border: none;
            cursor: pointer;
            font-size: 14px;
            padding: 4px 10px;
            border-radius: 30px;
            text-align: center;
            margin: 0px 6px 6px 0px;
            color: $white;              
          }
        }
      }
      @media only screen and (min-width: $xl-screen) {
        margin-left: 10%;
        transform: translateY(-30px);
        margin-bottom: 0px;
        .name {
          flex-direction: row;
          align-items: flex-end;
          padding-top: 0px;
          h1 {
            font-size: 26px;
            margin-right: 10px;
          }
          p {
            margin: 0px;
          }
          .poles {
            position: absolute;
            top: 85px;
          }
        }
      }
    }
      h2 {
        color: $primary-color;
        margin-bottom: 20px;
        font-size: 16px;
      }
      .container-field {
        @include containerField();
        label {
          @include label;
          display: block;
          margin-bottom: 10px;
        }
        .reset-color {
          margin-top: 5px;
          font-size: 12px;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .links {
        display: flex;
        margin-bottom: 20px;
        .link {
          @include semiBold;
          text-decoration: none;
          margin-right: 10px;
          font-size: 18px;
          &:last-child {
            color: $primary-color;
            border-bottom: 1px solid $primary-color;
          }
        }
      }
      .profile {
        margin-top: 20px;
      } 
      .col {
        margin-bottom: 25px;
      }
      
  
  @media only screen and (min-width: $xl-screen) {
    .bg {
      display: block;
    }
    .content {
      padding: 0px;
      .form {
        padding: 0px 10%;
        .forecast {
          max-width: 700px;
          width: 100%;
        }
      }
    }
  }
}