@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  .bg {
    left: 0px;
    height: 130px;
    width: 100%;
    background-color: #F6F7F8;
    background-image: url("../../assets/images/water.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
    position: relative;
    margin-bottom: 10px;
  }
  pointer-events: none;
  &.active {
    pointer-events: all;
  }
  .content {
    padding: 20px;
    margin-bottom: 30px;
    .avatar {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-bottom: 20px;
      .name {
        margin: 40px 0px 0px 0px;
        h1 {
          @include semiBold;
          font-size: 20px;
          margin: 0px;
          line-height: 22px;
          color: $primary-color;
        }
        p {
          margin: 5px 0px 0px 0px;
          line-height: 16px;
        }
      }
      @media only screen and (min-width: $xl-screen) {
        flex-direction: row;
        margin-left: 10%;
        transform: translateY(-30px);
        margin-bottom: 0px;
        justify-content: flex-start;
        align-items: center;
        .name {
          margin: 15px;
          padding-left: 0px;
          h1 {
            br {
              display: none;
            }
          }
        }
      } 
    }
    .form {
      .container-field {
        @include containerField();
      }
      h2 {
        color: $primary-color;
        margin-bottom: 10px;
        font-size: 14px;
      }
      .infos {
        .field {
          display: flex;
          width: 100%;
          p {
            margin-bottom: 16px;
            &:first-child {
              width: 25%;
              min-width: 100px;
              max-width: 120px;
              opacity: 0.5;
            }
            &:last-child {
              color: $black;
              width: 75%;
            }
          }
        }
      }
      .title {
        display: flex;
        align-items: center;
        h2 {
          margin: 0px;
        }
        .add {
          @include btn-edit;
          margin-left: 5px;
          .icon {
            width: 26px;
            height: 26px;
          }
        }
      }
      .admins {
        margin-top: 20px;
        display: grid;
        grid-gap: 20px;
        grid-template-columns: repeat(1, 1fr);
        @media only screen and (min-width: $xl-screen) {
          grid-template-columns: repeat(2, 1fr);
        }
      }
      .pole {
        margin-top: 20px;
        .poles {
          margin-top: 20px;
          display: grid;
          grid-gap: 20px;
          grid-template-columns: repeat(1, 1fr);
          @media only screen and (min-width: $xl-screen) {
            grid-template-columns: repeat(3, 1fr);
          }
          button {
            background-color: transparent;
            border: 1px solid #e6e6e8;
            padding: 10px;
            border-radius: 5px;
            display: flex;
            align-items: center;
            @include semiBold;
            @include transition;
            cursor: pointer;
            opacity: 1;
            &:hover {
              background-color: rgba($black, 0.05);
            }
            .icon {
              @include flex-center;
              width: 44px;
              height: 44px;
              border-radius: 22px;
              margin-right: 10px; 
            }
            p {
              @include semiBold;
              margin: 0px;
              color: $black;
              font-size: 16px;
              &.archived {
                margin-left: 10px;
                @include regular;
                opacity: 0.3;
              }
            }
          }
        }
      }
      .pool {
        margin-top: 20px;
        .pools {
          margin-top: 20px;
          display: grid;
          grid-gap: 20px;
          grid-template-columns: repeat(1, 1fr);
          @media only screen and (min-width: $xl-screen) {
            grid-template-columns: repeat(3, 1fr);
          }
          button {
            background-color: transparent;
            border: 1px solid #e6e6e8;
            padding: 10px 15px;
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: left;
            @include semiBold;
            @include transition;
            cursor: pointer;
            opacity: 1;
            &:hover {
              background-color: rgba($black, 0.05);
            }
            .icon {
              @include flex-center;
              width: 44px;
              height: 44px;
              border-radius: 22px;
              margin-right: 10px; 
            }
            p {
              @include semiBold;
              margin: 0px;
              color: $black;
              font-size: 16px;
              &:last-child {
                @include regular;
                font-size: 14px;
                color: rgba($black, 0.5);
              }
            }
          }
        }
      }
      .delete {
        background-color: transparent;
        @include semiBold;
        border: none;
        padding: 0px;
        display: flex;
        align-items: center;
        color: $warn;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        svg {
          margin: 0px 5px 2px 0px;
          font-size: 16px;
        }
      }
    }
  }
  @media only screen and (min-width: $xl-screen) {
    .bg {
      display: block;
    }
    .content {
      padding: 0px;
      .form {
        padding: 0px 10%;
        .profile {
          display: flex;
          .col {
            display: flex;
            flex-direction: column;
            width: 33%;
            margin-right: 20px;
            &.full {
              width: calc(66% + 20px);
            }
          }
        }
      }
    }
  }
}

.delete-modale {
  padding: 20px;
  @include flex-center;
  flex-direction: column;
  p {
    margin: 20px 0px 30px 0px;
    text-align: center;
    color: $black;
  }
  button {
    @include btn;
  }
} 