@import '../../../styles/variables.scss';
@import '../../../styles/mixins';
@import '../form-mixin.scss';


.container {
  position: relative;
  .img {
    width: 95px;
    height: 95px;
    border-radius: 95px;
    background-color: #c3c3c3;
    position: relative;
    overflow: hidden;
    img , video {
      position: relative;
      z-index: 0;
      width: 100%;
      height: 100%;
      border-radius: 95px;
      object-fit: cover;
    }
    @media only screen and (min-width: $xl-screen) {
      border: 3px solid $white;
      width: 120px;
      height: 120px;
      border-radius: 120px;
    }
  }
  .input {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    label {
      @include flex-center;
      z-index: 1;
      font-size: 16px;
      width: 36px;
      min-height: 36px;
      border-radius: 20px;
      color: $white;
      background-color: #A2B1CE;
      border: 1px solid transparent;
      position: absolute;
      bottom: 0px;
      left: -10px;
      &:hover {
        background-color: $primary-color;
      }
    }
    input {
      display: none;
    }
  }
  &.structure {
    label {
      bottom: -18px;
      left: calc(50vw - 40px);
    }
    .img { 
      width: 100%;
      height: 120px;
      border-radius: 11px;
      @media only screen and (min-width: $xl-screen) {
        width: 280px;
      }
      img {
        border-radius: 10px;
      }
    }
    @media only screen and (min-width: $xl-screen) {
      label {
        bottom: 45px;
        left: -16px;
      }
    }
  }
}