@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.filters {
  top: 0px;
  z-index: 200;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #f1f1f1;
  transform: translateX(100%);
  @include transition;
  &.active {
    transform: translateX(0px);
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px 20px 20px;
    h4 {
      margin: 0px;
      @include semiBold;
      font-size: 26px;
      line-height: 26px;
      color: $black;
    }
    button {
      background-color: transparent;
      cursor: pointer;
      border: none;
      padding: 0px;
      margin-top: 4px;
      font-size: 30px;
    }
  }
  .content {
    width: 100%;
    min-height: 85vh;
    padding: 10px 20px 20px;
    overflow-y: auto;
    label {
      @include semiBold;
    }
    .poles {
      width: 100%;
      margin-top: 8px;
      button {
        width: 100%;
        background-color: transparent;
        border: 1px solid #A2B1CE;
        padding: 8px 10px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        @include semiBold;
        @include transition;
        cursor: pointer;
        margin-bottom: 10px;
        &.active {
          background-color: $white;
          border: 1px solid #F1F0F3;
          p {
            color: $black;
          }
        }
        .icon {
          @include flex-center;
          width: 44px;
          height: 44px;
          border-radius: 22px;
          margin-right: 10px; 
        }
        p {
          @include semiBold;
          margin: 0px;
          color: #A2B1CE;
          font-size: 16px;
        }
      }
    }
  }
  .btn {
    @include btn;
    margin-bottom: 20px;
  }
}