@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input {
  position: relative;
  display: flex;
  align-items: center;
  .label {
    display: block;
    margin-top: 1px;
    margin-left: 0px;
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 6px;
    }
  }

  .input {
    display: none;
  }
  &.login {
    @include login;
  }
  
  .checkmark {
    position: relative;
    cursor: pointer;
    border: 2px solid #cccdd1;
    top: 0;
    left: 8px;
    height: 18px;
    width: 18px;
    border-radius: 3px;
    background-color: $white;
    /* On mouse-over, add a grey background color */
    &:hover {
      background-color: #ccc;
    }
    
    &.checked {
      background-color: $primary-color;
      border: 2px solid $primary-color;
      &:after {
        display: block;
      }
    }
    /* Create the checkmark/indicator (hidden when not checked) */
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
    
    /* Style the checkmark/indicator */
    &:after {
      left: 3px;
      top: 0px;
      width: 4px;
      height: 8px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}
