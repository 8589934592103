@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .edit {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
    @include btn-edit;
    @media only screen and (min-width: $xl-screen) {
      top: 85px;
      right: 10%;
    }
  }
  .main {
    @include content;
    @media only screen and (min-width: $xl-screen) {
      background-color: $white;
      position: relative;
    }
    .container {
      .bg {
        left: 0px;
        height: 130px;
        width: 100%;
        background-color: #F6F7F8;
        background-image: url("../../assets/images/water.svg");
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
      }
      .content {
        padding: 20px;
        .avatar {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 20px;
          .name {
            margin: 0px 10px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            h1 {
              @include semiBold;
              font-size: 26px;
              line-height: 22px;
              margin: 0px;
              color: $primary-color;
            }
            p {
              font-size: 20px;
              line-height: 18px;
              margin: 5px 0px;
            }
          }
          @media only screen and (min-width: $xl-screen) {
            margin-left: 10%;
            transform: translateY(-30px);
            margin-bottom: 0px;
            .name {
              flex-direction: row;
              align-items: flex-end;
              padding-top: 0px;
              h1 {
                font-size: 26px;
                margin-right: 10px;
              }
              p {
                margin: 0px;
              }
              .poles {
                position: absolute;
                top: 85px;
              }
            }
          }
        }
        .form {
          position: relative;
          h2 {
            color: $primary-color;
            margin-bottom: 20px;
            font-size: 16px;
          }
          .container-field {
            @include containerField();
          }
          .edit {
            right: 0px;
            top: 0px;
            @media only screen and (min-width: $xl-screen) {
              right: 10%;
            }
          }
          .links {
            display: flex;
            .link {
              cursor: pointer;
              @include semiBold;
              text-decoration: none;
              margin-right: 10px;
              font-size: 18px;
              &:nth-child(2) {
                color: $primary-color;
                border-bottom: 1px solid $primary-color;
              }
            }
          }
        }
      }
      @media only screen and (min-width: $xl-screen) {
        .bg {
          display: block;
        }
        .content {
          padding: 0px;
          .form {
            padding: 0px 10% 40px 10%;
          }
        }
      }
    }
  }
}

.list {
  margin-top: 30px;
  @media only screen and (min-width: $xl-screen) {
    margin-top: 20px;
  }
  .row {
    display: flex;
    flex-direction: column;
    padding: 10px;
    @media only screen and (min-width: $xl-screen) {
      flex-direction: row;
      padding: 0px;
    }
    .col {
      width: calc(100% / 5);
      padding: 0px;
      @media only screen and (min-width: $xl-screen) {
        padding: 10px;
        width: calc(60% / 4);
        &:nth-child(4) {
          width: 40%;
        }
      }
    }
    &.labels {
      color: rgba($black, 0.5);
      display: none;
      @media only screen and (min-width: $xl-screen) {
        display: flex;
      }
    }
    &:not(.labels) {
      @include transition;
      border: 1px solid #CFCFCF;
      border-radius: 5px;
      margin-bottom: 6px;
      cursor: pointer;
      &:hover {
        background-color: rgba($black, 0.05);
      }
    }
  }
}
