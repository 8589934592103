@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .content {
    @include content;
    background-color: #F6F7F8;
    border-top: 1px solid rgba($black, 0.1);
    overflow-x: hidden;
    .row {
      display: flex;
      flex-direction: column;
      .col {
        width: 100%;
        display: flex;
        flex-direction: column;
        h1 {
          @include semiBold;
          font-size: 20px;
          line-height: 20px;
          color: $primary-color;
          margin: 0px;
        }
        .timeline {
          height: 50px;
          width: 100%;
          .empty {
            width: 100%;
            height: 45px;
            display: flex;
            align-items: center;
            background-color: #F6F7F8;
            border-radius: 5px;
            padding: 10px 15px;
            margin-top: 3px;
            p {
              margin: 0px;
              color: $black;
            }
          }
        }
        label {
          @include label;
          display: block;
          margin-bottom: 8px;
          @media only screen and (min-width: $xl-screen) {
            color: $black;
          }
        }
      }
    }
    .header {
      background-color: $white;
      padding: 20px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      @media only screen and (min-width: $xl-screen) {
        padding: 10px 5% 30px 5%;
      }
      .row {
        &:last-child {
          margin-top: 10px;
          flex-direction: row;
          justify-content: flex-start;
          .col {
            width:  calc(33% - 10px);
            display: flex;
            flex-direction: column;
            margin-right: 20px;
            @include flex-center;
            p {
              width: 100%;
              background-color: rgba(#33B0C5, 0.1);
              color: #33B0C5;
              border-radius: 5px;
              font-size: 18px;
              text-align: center;
              margin: 0px;
              @include semiBold;
              padding: 5px;
            }
            @media only screen and (min-width: $xl-screen) {
              background-color: #F6F7F8;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              border-radius: $radius;
              padding: 10px;
              label {
                margin: 0px 10px;
              }
              p {
                max-width: 140px;
              }
            }
          }
        }
        @media only screen and (min-width: $xl-screen) {
          flex-direction: row;
          justify-content: space-between;
          .col {
            margin-top: 20px;
            width:  calc(33% - 10px);
          }
        }
      }
    }
    .shifts {
      padding: 20px;
      .col {
        margin-bottom: 20px;
        label {
          color: $black;
        }
        .timeline {
          .empty { 
            background-color: $white;
          }
        }
      }
    }
    .week {
      margin-top: 20px;
      margin-bottom: 30px;
      .validation {
        justify-content: flex-end;
        margin-right: 5%;
        button {
          @media only screen and (min-width: $xs-screen) {
            width: 200px;
          }
        }
        .valid {
          width: 200px;
          @media only screen and (min-width: $xs-screen) {
            width: 200px;
          }
        }
      } 
      .title {
        @media only screen and (min-width: $xl-screen) {
          padding: 0px 5% 0px 5%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        p {
          margin: 0px;
          @include semiBold;
          color: $black;
        }
      }
      .labels {
        width: 100%;
        padding: 0px 20px;
        display: none;
        @media only screen and (min-width: $xl-screen) {
          padding: 12px 5% 6px 5%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .row {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          &:first-child {
            width: 220px;
          }
          &:last-child {
            width: calc(100% - 220px);
            .hour {
              position: relative;
              p {
                margin: 0px;
                line-height: 14px;
              }
            }
          }
        }
      }
      .list {
        padding: 0px 5% 0px 5%;
        .days {
          background-color: $white;
          border-radius: 7px;
        }
      }  
    }
    .validation {
      display: flex;
      justify-content: center;
      margin-top: 10px;
      button {
        @include btn;
        width: 100%;
        @media only screen and (min-width: $xs-screen) {
          width: 280px;
        }
      }
      .valid {
        @include btn;
        background-color: $white;
        @include flex-center;
        padding: 6px 20px;
        width: 100%;
        @media only screen and (min-width: $xs-screen) {
          width: 280px;
        }
        .icon {
          @include flex-center;
          margin-right: 10px;
          width: 32px;
          height: 32px;
          border-radius: 16px;
          background-color: rgba($success, 0.3);
          color: $success;
          font-size: 20px;
        }  
        p {
          @include semiBold;
          margin: 0px;
          color: $black;
        }
      }
    }
  }
}