@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.mobile {
  position: fixed;
  bottom: 0px;
  width: 100%;
  background-color: $primary-color;
  height: 65px;
  display: flex;
  justify-content: center;
  padding: 0px 10px;
  a {
    width: calc((100% - 20px) / 4);
    position: relative;
    @include transition;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    opacity: 0.6;
    color: $white;
    font-size: 14px;
    line-height: 14px;
    img {
      margin-bottom: 5px;
    } 
    &.active {
      opacity: 1;
    }
    .count {
      @include flex-center;
      @include transition;
      top: 15px;
      left: 54%;
      position: absolute;
      width: 18px;
      height: 18px;
      font-size: 11px;
      border-radius: 18px;
      color: $white;
      background-color: $warn;
      transform: scale(0);
      &.active {
        transform: scale(1);
      }
    }
  }
  &.member {
    a {
      width: calc((100% - 20px) / 3);
      text-align: center        
    }
  }
  @media only screen and (min-width: $xl-screen) {
    display: none;
  }
}

.desktop {
  position: fixed;
  top: 0px;
  width: 100%;
  background-color: $white;
  height: 70px;
  justify-content: space-between;
  padding: 0px 10px;
  display: none;
  .nav  {
    display: flex;
    align-items: center;
    img {
      height: calc(100% - 20px);
      margin-left: 20px;
      margin-right: 40px;
    }
    a {
      @include semiBold;
      @include transition;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      opacity: 0.4;
      color: $black;
      font-size: 16px;
      line-height: 16px;
      margin-right: 50px;
      &::after {
        content: '';
        @include transition;
        position: absolute;
        width: 50px;
        height: 0px;
        margin-top: 42px;
        background-color: $black;
      }
      &.active {
        opacity: 1;
        &::after {
          height: 2px;
        }
      }
    }
  }
  .user {
    display: flex;
    align-items: center;
    .export {
      @include btn;
      @include semiBold;
      color: $primary-color;
      background-color: transparent;
      border: 1px solid rgba($primary-color, 0.4);
      border-radius: 3px;
      margin-right: 14px;
      padding: 6px 12px;
      cursor: pointer;
      &:hover {
        background-color: rgba($primary-color, 0.2);
      }
    }
    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      p {
        margin: 0px;
        @include semiBold;
        text-decoration: none;
        color: $primary-color;
        margin-right: 15px;
      }
      .profile {
        @include flex-center;
        width: 48px;
        height: 48px;
        border-radius: 25px;
        background-color: $primary-color;
        margin-right: 15px;
        img {
          width: 100%;
          height: 100%;
          border-radius: 25px;
          object-fit: cover;
        }
        p {
          margin: 0px;
          @include semiBold;
          position: relative;
          text-align: center;
          width: 35px;
          color: $white;
          font-size: 16px;
          text-decoration: none;
          text-transform: uppercase;
        }
      }
    }
    .notifs {
      position: relative;
      background-color: transparent;
      border: none;
      margin-right: 10px;
      cursor: pointer;
      svg {
        font-size: 20px;
        color: $primary-color;
      }
      .count {
        @include flex-center;
        @include transition;
        top: 8px;
        right: -4px;
        position: absolute;
        width: 18px;
        height: 18px;
        font-size: 11px;
        border-radius: 18px;
        color: $white;
        background-color: $warn;
        transform: scale(0);
        &.active {
          transform: scale(1);
        }
      }
    }
  }
  @media only screen and (min-width: $xl-screen) {
    display: flex;
  }
}

.notifs-container {
  @include transition;
  z-index: 200;
  position: fixed;
  top: 65px;
  right: 15px;
  width: 440px;
  border-radius: 11px;
  border: 1px solid #F1F0F3;
  background: $white;
  transform: translateX(550px);
  &.active {
    transform: translateX(0px);
  }
}