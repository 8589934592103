@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.header {
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  h1 {
    margin: 0px 20px;
    color: $primary-color;
    font-size: 26px;
    line-height: 26px;
  }
  a {
    text-decoration: none;
  }
  .profile {
    @include flex-center;
    width: 48px;
    height: 48px;
    border-radius: 25px;
    background-color: $primary-color;
    margin-right: 15px;
    text-decoration: none;
    img {
      width: 100%;
      height: 100%;
      border-radius: 25px;
      object-fit: cover;
    }
    p {
      margin: 0px;
      @include semiBold;
      position: relative;
      text-align: center;
      width: 35px;
      color: $white;
      font-size: 16px;
      text-decoration: none;
    }
  }
  @media only screen and (min-width: $xl-screen) {
    display: none;
  }  
}