@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .edit {
    position: absolute;
    top: 10px;
    right: 15px;
    z-index: 10;
    @include btn-edit;

    @media only screen and (min-width: $xl-screen) {
      top: 85px;
      right: 10%;
    }
  }

  .content {
    position: relative;
    @include content;

    @media only screen and (min-width: $xl-screen) {
      background-color: $white;
      position: relative;
    }

    .nav {
      position: absolute;
      top: 15px;
      right: 15px;
      display: none;

      button {
        background-color: $white;
        color: $primary-color;
        width: 40px;
        height: 40px;
        @include flex-center;
        border: 1px solid #F1F0F3;
        cursor: pointer;

        &:first-child {
          margin-right: 10px;
        }
      }

      @media only screen and (min-width: $xl-screen) {
        display: flex;
        right: 10%;
        top: 15px;
      }
    }
  }
}