@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  padding: 26px;
  h4 {
    color: $primary-color;
    font-size: 16px;
  }
  .text {
    textarea {
      min-height: 120px;
      padding: 10px;
    }
  } 
  .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    button {
      @include btn;
      &.warn {
        background-color: transparent;
        color: $warn;
        &:hover {
          background-color: rgba($warn, 0.2)
        }
      }
    }
  }
}