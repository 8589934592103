@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.modale {
  padding: 20px;
  h2 {
    color: $primary-color;
    margin-bottom: 20px;
    font-size: 14px;
  }
  .poles {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, 1fr);
    @media only screen and (min-width: $xl-screen) {
      grid-template-columns: repeat(2, 1fr);
    }
    button {
      background-color: transparent;
      border: 1px solid #F1F0F3;
      padding: 10px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      @include semiBold;
      @include transition;
      cursor: pointer;
      opacity: 0.4;
      &.active {
        opacity: 1;
      }
      &:hover {
        background-color: rgba($black, 0.05);
      }
      .icon {
        @include flex-center;
        width: 44px;
        height: 44px;
        border-radius: 22px;
        margin-right: 10px; 
      }
      p {
        @include semiBold;
        margin: 0px;
        color: $black;
        font-size: 16px;
      }
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    button {
      display: flex;
      @include btn;
      &:first-child {
        border: 1px solid $primary-color;
        background-color: transparent;
        color: $primary-color;
        .icon {
          @include flex-center;
          margin-left: 20px;
          width: 26px;
          height: 26px;
          color: $primary-color;
          border-radius: 26px;
          background-color: rgba($primary-color, 0.5);
          svg {
            color: $black;
            top: 0px;
          }
        }
        &:hover {
          background-color: rgba($primary-color, 0.1);
        }
      } 
    }
  }
}