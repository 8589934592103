@import "../../styles/_variables.scss";
@import "../../styles/_mixins.scss";

.container {
  .content {
    @include content;
    overflow: hidden;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px 20px;

      h1 {
        font-size: 26px;
        margin: 0px;
        line-height: 26px;
        color: $black;
        display: none;
      }

      @media only screen and (min-width: $xl-screen) {
        padding: 10px 5% 0px 5%;
        display: flex;

        h1 {
          display: block;
        }
      }
    }

    .nav {
      width: 100%;
      display: flex;
      justify-content: center;

      @media only screen and (min-width: $xl-screen) {
        justify-content: flex-start;
        padding: 15px 5% 15px 5%;
      }

      button {
        @include semiBold;
        @include transition;
        cursor: pointer;
        font-size: 16px;
        background-color: transparent;
        border: none;
        padding: 0px;
        color: rgba($black, 0.4);
        margin: 20px;

        @media only screen and (min-width: $xl-screen) {
          margin: 0px 20px 0px 0px;
        }

        .bar {
          background-color: transparent;
          width: 80%;
          height: 2px;
          margin: auto;
        }

        &.active {
          color: $black;

          .bar {
            background-color: $primary-color;
          }
        }
      }
    }

    .filters {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 0px 22px;
      justify-content: space-between;

      @media only screen and (min-width: $xl-screen) {
        padding: 0px 5% 0px 5%;
        flex-direction: row;
        justify-content: space-between;
      }

      .row {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        @media only screen and (min-width: $xl-screen) {
          width: 50%;
          margin-bottom: 0px;
        }

        &:last-child {
          justify-content: flex-end;
        }

        .select-date-mobile {
          width: 100%;
        }

        .select-date {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 0px;
          margin-right: 20px;
          width: 90%;

          button {
            width: 28px;
            height: 28px;
            border-radius: 15px;
            @include flex-center;
            border: none;
            cursor: pointer;
            background-color: rgba($primary-color, 0.2);

            svg {
              font-size: 22px;
              transform: translateX(4px) rotate(90deg);
            }

            &:last-child {
              transform: translateX(4px) rotate(180deg);
            }

            &:hover {
              background-color: rgba($primary-color, 0.4);
            }
          }

          .dates {
            @include flex-center;

            .favorite {
              background-color: transparent;
              cursor: pointer;

              svg {
                font-size: 20px;
                transform: translate(6px, 2px);
              }
            }

            .holiday {
              width: 30px;
              cursor: pointer;
              opacity: 0.1;

              &.none {
                display: none;
              }

              &.active {
                opacity: 1;
              }

              &:hover {
                opacity: 0.5;
              }

              svg {
                font-size: 20px;
                transform: translate(6px, 3px);
              }
            }
          }

          h4 {
            margin: 0px;
            text-align: center;
            font-size: 17px;
            color: $black;
          }
        }

        .col {
          width: 100%;
          margin-top: 0px;

          @media only screen and (min-width: $xl-screen) {
            width: calc(50% - 10px);
            margin-left: 10px;
          }
        }

        .action {
          margin-left: 10px;
        }

        .import,
        .publish {
          cursor: pointer;
          margin-top: 0px;
          background-color: $white;
          border: 1px solid #F1F0F3;
          border-radius: 5px;
          height: 50px;
          min-width: 50px;

          &:hover {
            background-color: #dfe4ea;
          }
        }

        .publish {
          font-size: 20px;
          color: rgba($primary-color, 0.4);
          border: 1px solid #F1F0F3;

          &:hover {
            background-color: $white;
            color: $success;
          }

          svg {
            transform: translateY(2px);

          }

          &.active {
            color: $success;
            background-color: rgba($success, 0.3);
          }
        }
      }
    }

    .labels {
      width: 100%;
      padding: 0px 20px;
      display: none;
      align-items: center;

      @media only screen and (min-width: $xl-screen) {
        padding: 12px 5% 6px 5%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .row {
        display: flex;
        justify-content: flex-start;

        &:first-child {
          width: 220px;

          .sort {
            width: 99%;
            background-color: transparent;
            border: none;
            display: flex;
            align-items: center;
            justify-content: space-between;
            cursor: pointer;

            p {
              @include semiBold;
              color: $black;
              line-height: 14px;
              margin: 0px;
            }

            svg {
              transform: translateY(-1px);
            }
          }
        }

        &:last-child {
          width: calc(100% - 220px);

          .hour {
            position: relative;

            p {
              margin: 0px;
              line-height: 14px;
            }
          }
        }
      }
    }

    .mobile-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 20px 0px 20px;

      h4 {
        margin: 0px 4px;
        text-align: center;
        font-size: 18px;
        color: $black;
      }

      button {
        position: relative;
        cursor: pointer;
        background-color: transparent;
        border: none;
        font-size: 28px;

        .count {
          @include flex-center;
          position: absolute;
          top: 8px;
          right: -3px;
          width: 20px;
          height: 20px;
          background-color: #A2B1CE;
          border-radius: 20px;

          p {
            color: $white;
            font-size: 12px;
            margin: 0px;
          }
        }
      }

      @media only screen and (min-width: $xl-screen) {
        display: none;
      }
    }

    .list {
      max-height: calc(100vh - 400px);
      min-height: calc(100vh - 379px);
      overflow-y: auto;
      overflow-x: hidden;
      padding: 20px 20px;

      .grid {
        margin-bottom: 0px;
        border-radius: 7px;
        background-color: $white;
        overflow: hidden;
      }

      @media only screen and (min-width: $xl-screen) {
        max-height: calc(100vh - 200px);
        padding: 5px 5% 40px 5%;
        @include scrollbar;

        .grid {
          background-color: $white;
          overflow: visible;
        }
      }

      .delete {
        background-color: transparent;
        border: none;
        @include regular;
        cursor: pointer;
        text-decoration: underline;
        float: right;
        color: $warn;
      }
    }
  }
}