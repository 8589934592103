@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.modale {

  h2 {
    padding: 20px;
    color: $primary-color;
    margin-bottom: 0px;
    font-size: 18px;
  }
  .users {
    @include scrollbar;
    padding: 0px 20px;
    max-height: 430px;
    overflow-y: auto;
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
    .card {
      @include transition;
      display: flex;
      align-items: center;
      position: relative;
      background: $white;
      box-shadow: none;
      border: 1px solid #F1F0F3;
      border-radius: 11px;
      padding: 12px;
      text-decoration: none;
      cursor: pointer;
      &:hover {
        background: rgba($primary-color, 0.1);
      }
      &.active {
        background: rgba($primary-color, 0.2);
      }
      &.archived {
        opacity: 0.4;
      }
      .role {
        position: absolute;
        top: 6px;
        right: 10px;
        font-size: 18px;
        color: $black;
      }
      .picture {
        height: 60px;
        width: 60px;
        border-radius: 30px;
        background-color: $primary-color;
        @include flex-center;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 30px;
        }
        p {
          @include semiBold;
          color: $white;
          font-size: 18px;
          margin: 0px;
        }
      }
      .infos {
        margin-left: 10px;
        h3, h4 {
          margin: 0px;
          text-decoration: none;
        }
        h3 {
          font-size: 18px;
          line-height: 18px;
          margin-bottom: 5px;
          color: $black;
        }
        h4 {
          @include regular;
          line-height: 14px;
        }
      }
    }
  }
  .actions {
    padding: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      @include btn;
    }
  }
}