@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.modale {
  padding: 20px;
  max-height: 70vh;
  overflow: auto;
  position: relative;
  .title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    h2 {
      color: $primary-color;
      margin: 0px;
      font-size: 18px;
    }
    p {
      margin: 0px 10px;
      line-height: normal;
    }
  }
  .form {
    .container-field {
      @include containerField();
      .icon {
        display: flex;
        align-items: center;
        label {
          display: block;
          min-width: 70px;
          @include regular;
          font-size: 14px;
          line-height: 14px; 
          color: $text-color;
          white-space: nowrap;
        }
      }
    }
  }
  .activities {
    margin-bottom: 20px;
    h2 {
      font-size: 14px;
      margin-bottom: 10px;
    }
    .input {
      width: 100%;
      border: solid 1px #d8d7da;
      border-radius: $radius;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        height: 40px;
        width: calc(100% - 120px);
        padding: 0px 10px; 
        border: none;
        @include regular;
        font-size: 16px;
        background-color: transparent;
        &::placeholder {
          color : rgba($text-light, 0.6);
        }
      }
      button {
        @include btn;
        pointer-events: none;
        opacity: 0.2;
        &.active {
          pointer-events: all;
          opacity: 1;
        }
      }
    }
    .list {
      margin-top: 20px;
      .activity {
        border: solid 1px #d8d7da;
        border-radius: $radius;
        display: flex;
        justify-content: space-between;
        padding: 8px 10px;
        margin-top: 10px;
        p {
          margin: 0px;
          font-size: 16px;
          color: $black;
          width: 60%;
        }
        .color {
          position: relative;
        }
        button {
          padding: 0px;
          background-color: transparent;
          border: none;
          font-size: 19px;
          opacity: 0;
          pointer-events: none;
        }
        &:hover {
          background-color: rgba($primary-color, 0.1);
          border: solid 1px $primary-color;
          button {
            cursor: pointer;
            pointer-events: all;
            opacity: 0.4;
          }
        }
      }
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    button {
      @include btn;
      &:last-child {
        background-color: transparent;
        color: $primary-color;
      }
    }
  }
}