@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{

  label {
    @include label;
  }
  
  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }
  .tags {
    button {
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 30px;
      margin: 0px 6px 6px 0px;
      p {
        color: $white;
        margin: 0px;
      }
    }
  }
  
}
