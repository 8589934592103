@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.step {
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  @include transition;
  .content {
    position: relative;
    width: calc(100% - 2px);
    height: calc(100% - 3px);
    border-radius: 3px;
    display: flex;
    overflow: hidden;
    @media only screen and (min-width: $xl-screen) {
      width: calc(100% - 4px);
      height: calc(100% - 6px);
      border-radius: 5px;
    }
    .label {
      padding: 10px;
      z-index: 10;
      color: rgba($black, 0.4);
      &.line {
        display: flex;
        align-items: center;
        p {
          margin-right: 5px;
          margin-bottom: 0px;
        }
      }
      p {
        text-align: left;
        color: inherit;
        margin: 0px;
        color: $white;
        font-size: 16px;
        line-height: 16px;
        margin-bottom: 5px;
        @include semiBold;
        &:not(:first-child) {
          @include regular;
          line-height: 14px;
          font-size: 14px;
        }
      }
      &.empty {
        p {
          color: rgba($black, 0.4);
        }
      }
    }
  }
}