@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.modale {
  padding: 20px;
  .title {
    display: flex;
    align-items: center;
    margin: 20px 0px;
    @media only screen and (min-width: $xl-screen) {
      margin-top:  0px;
    }
    h2 {
      color: $primary-color;
      margin-bottom: 0px;
      font-size: 14px;
      margin-right: 5px;
    }
    .avatar {
      height: 35px;
      width: 35px;
      border-radius: 30px;
      margin-right: 5px;
      background-color: $primary-color;
      @include flex-center;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30px;
      }
    }
  }
  .row {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba($black, 0.2);
    margin-bottom: 20px;
    @media only screen and (min-width: $xl-screen) {
      flex-direction: row;
      justify-content: space-between;
    }
    .field {
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      label {
        @include semiBold;
        color: rgba($black, 0.5);
        display: block;
        margin-bottom: 5px;
        margin-right: 20px;
      }
    }
  }
  .poles {
    display: flex;
    flex-wrap: wrap;
    button {
      position: relative;
      border: none;
      background-color: transparent;
      padding: 0px;
      margin-right: 8px;
      margin-bottom: 8px;
      border-radius: 5px;
      display: flex;
      align-items: center;
      @include semiBold;
      @include transition;
      cursor: pointer;
      .icon {
        @include flex-center;
        width: 44px;
        height: 44px;
        opacity: 0.4;
        border-radius: 22px;
      }

      &.active {
        .icon {
          opacity: 1;
        }
      }
      .tooltip {
        @include transition;
        pointer-events: none;
        opacity: 0;
        z-index: 1000;
        bottom: -40px;
        left: 0px;
        position: absolute;
        background-color: $white;
        border-radius: 8px;
        padding: 6px 10px;
        box-shadow: 0px 0px 9px 8px rgba(211, 218, 226, 0.35);
        p {
          margin: 0px;
          color: $black;
          text-align: left;
          min-width: 90px;
        }
        .infos {
          display: flex;
          align-items: center;
          width: 100%;
          .puce {
            width: 6px;
            height: 13px;
            border-radius: 5px;
            margin-right: 5px;
          }
          &:last-child {
            justify-content: space-between;
            p {
              &:last-child {
                @include semiBold;
              }
            }
          }
        }
      }
      &:hover {
        .tooltip {
          opacity: 1;
        }
      }
    }
  }
  .lines {
    border-top: 1px solid rgba($black, 0.2);
    display: flex;
    align-items: center;
    padding-top: 10px;
    margin-top: 20px;
    label {
      @include semiBold;
      color: rgba($black, 0.5);
      display: block;
      margin-right: 20px;
    }
    .line {
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      .check {
        border: 1px solid rgba($black, 0.2);
        width: 24px;
        height: 24px;
        border-radius: 7px;
        @include flex-center;
        color: $primary-color;
        svg {
          opacity: 0;
        }
      }
      p {
        @include semiBold;
        color: $black;
        margin: 0px 8px;
      }
      &:hover {
        .check {
          svg {
            opacity: 0.5;
          }
        }
      }
      &.active {
        .check {
          svg {
            opacity: 1;
          }
        }
        &.current {
          opacity: 0.5;
        }
      }
    }
  }
  .activity {
    display: flex;
    align-items: center;
    label {
      @include semiBold;
      color: rgba($black, 0.5);
      display: block;
      margin-right: 20px;
    }
    .select {
      width: 220px;
    }
  }
  .daysToggle {
    display: flex;
    justify-content: flex-end;
    button {
      background-color: transparent;
      border-radius: 5px;
      color: rgba($black, 0.5);
      padding: 6px 10px;
      margin-left: 10px;
      cursor: pointer;
      border: 1px solid rgba($black, 0.2);
      @include semiBold;
      &.active {
        color: $primary-color;
        border: 1px solid $primary-color;
      }
    }
  }
  .hours {
    margin-top: 20px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    @media only screen and (min-width: $md-screen) {
      flex-direction: row;
      align-items: center;
    }
    .select {
      display: flex;
      align-items: center;
    }
    .label {
      @include semiBold;
      color: rgba($black, 0.5);
      display: block;
      margin: 0px;
    }
    .col {
      margin: 6px;
    }
    .duration {
      display: flex;
      align-items: center;
    }
  }
  .count {
    margin: 20px 0px;
  }
  .moreDays {
    .dates {
      margin: 15px 0px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      .field {
        margin-bottom: 10px;
        width: 100%;
        label {
          @include semiBold;
          color: rgba($black, 0.5);
          display: block;
          margin-bottom: 5px;
          margin-right: 20px;
        }
      }
    }
    .text {
      textarea {
        min-height: 120px;
        font-size: 14px;
      }
    }
  }
  .errors {
    margin-top: 20px;
    .error {
      background-color: rgba($warn, 0.2);
      @include semiBold;
      padding: 8px 12px;
      border-radius: 6px;
      margin-bottom: 5px;
      p {
        @include semiBold;
        color: $warn;
        margin: 0px;
      }
    }
  }
  .alerts {
    margin-top: 20px;
    .alert {
      background-color: rgba($medium-warn, 0.2);
      @include semiBold;
      padding: 8px 12px;
      border-radius: 6px;
      margin-bottom: 5px;
      p {
        @include semiBold;
        color: $medium-warn;
        margin: 0px;
      }
    }
  }
  .actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .delete {
      background-color: transparent;
      text-decoration: underline;
      padding: 0px;
      border: none;
      color: $warn;
      @include regular;
      cursor: pointer;
    }
    .add {
      display: flex;
      @include transition;
      @include btn;
      cursor: pointer;
    }
  }
}