@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.confirm {
  padding: 20px;
  p {
    @include semiBold;
    font-size: 16px;
    text-align: center;
    color: $black;
  }
  .actions {
    display: flex;
    justify-content: center;
    button {
      @include btn;
      width: 120px;
      &:first-child {
        background-color: transparent;
        color: $primary-color;
        margin: 0px 15px;
      }
    }
  }
}