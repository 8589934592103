@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.forecastForm {
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 35px;
    margin-bottom: 20px;
    .year {
      display: flex;
      align-items: center;
      h2 {
        margin: 0px 10px ;
      }
      button {
        width: 28px;
        height: 28px;
        border-radius: 15px;
        @include flex-center;
        border: none;
        cursor: pointer;
        background-color: transparent;
        svg {
          font-size: 22px;
          transform: translateX(4px) rotate(90deg);
        }

        &:last-child {
          transform: translateX(4px) rotate(180deg);
        }

        &:hover {
          background-color: rgba($primary-color, 0.1);
        }
      }
    }
    .submit {
      @include btn-edit;
    }
  }
  .month {
    width: 100%;
    margin-bottom: 30px;
    .label {
      width: 100%;
      border-bottom: 1px solid rgba($primary-color, 0.2);
      h3 {
        color: $primary-color;
        font-size: 16px;
        text-transform: capitalize;
        margin-bottom: 5px;
      }
    }
    .weeks {
      margin: 15px 0px;
      .week {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;
        .col {
          width: 50%;
          p {
            margin: 0px;
            span {
              @include semiBold;
              display: inline-block;
              width: 100px;
              color: $black;
            }
          }
        }
        .input {
          width: 50%;
          p {
            margin: 0px;
          }
        }
      }
    }
  }
}