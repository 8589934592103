@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.container {
  padding: 26px;

  h4 {
    color: $primary-color;
    font-size: 16px;
  }

  .field {
    margin-bottom: 15px;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    button {
      @include btn;

      &.warn {
        background-color: transparent;
        color: $warn;

        &:hover {
          background-color: rgba($warn, 0.2)
        }
      }
    }
  }
}