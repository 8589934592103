@import "../../styles/_variables.scss";
@import "../../styles/mixins";

.modale {
  .form {
    .container-field {
      @include containerField();
    }
  }
  
  .types {
    display: grid;
    grid-template-columns: auto auto auto;
    margin-bottom: 20px;
    gap: 20px;
    button {
      @include btn;
      &:not(.active) {
        background-color: transparent;
        color: $primary-color;
      }
    }
  }
  .row {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr;
    .container-field {
      @include containerField();
    }
  }
  .error {
    margin-bottom: 20px;
  }
  .actions {
    button {
      @include btn;
    }
  }
}