@import '../../../styles/variables.scss';
@import '../form-mixin.scss';

.container-input{
  position: relative;
  label{
    @include label;
  }

  &.inline {
    @include inline;
  }

  &:not(.inline){
    label+.input {
      margin-top: 10px;
    }
  }

  .input {
    @include input;
    border: none;
    padding:4px 10px;
    width: 100%;
    input {
      border: none;
      font-size: 16px;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }
  &.forecast {
    @include primary;
    .input {
      padding: 5px 10px;
    }
  }
  &.primary {
    @include primary;
    position: relative;
    border-radius: 3px;
    input {
      border: none;
      font-size: 16px;
      position: relative;
      width: calc(100% - 70px);
    }
    &.error {
      .unit {
        background-color: #FDF0F0;
      }
    }
  }
  &.secondary {
    @include primary;
    border-radius: 3px;
    input {
      border: none;
      font-size: 16px;
      width: calc(100% - 70px);
    }
    &.error {
      .unit {
        background-color: #FDF0F0;
      }
    }
  }
  &.duration {
    @include primary;
    margin: 0px 5px;
    input {
      max-width: 40px;
      font-size: 16px;
      color: $black;
      border: none;
      text-align: center;
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      -moz-appearance: textfield;
    }
    &.error {
      .unit {
        background-color: #FDF0F0;
      }
    }
  }
  &.login {
    @include login;
  }
  .unit {
    @include label;
    pointer-events: none;
    position: absolute;
    right: 2px;
    top: 42px;
    background-color: $white;
    padding: 5px 10px;
    text-align: center;
    min-width: 70px;
    border-left: 1px solid #B1BAD8;
  }
}
